var caldic = caldic || {};
( function () {
    'use strict';
    jQuery( function () {
        caldic.init();

    } );
    caldic.init = function () {
        caldic.form();
    };

    caldic.form = function(){
    	if ( jQuery(".js-free-quote-form").length >= 1) {
			var caldicProductName = jQuery( '.caldic-product-name' ).text();
			var caldicProductNumber = jQuery( '.caldic-product-number' ).text();

			jQuery ('input[name="Productname"]').val( caldicProductName );
			jQuery ('input[name="Productnumber"]').val( caldicProductNumber );
		}
    }
}() );





